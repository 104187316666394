import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import io from 'socket.io-client';
import Message from '../components/Message';
import './styles.scss';
import GameBlock from './GameBlock';
import SidebarBlock from './SidebarBlock';

/**
 * All times and delays are in seconds.
 *
 * globalScoresScrollDelay - time to wait before start scrolling for the entire round scores. Ignored on the "scores" screen.
 * resultsScrollDelay - time to wait before start scrolling scores for that specific question.
 * resultsTime - how long to show scores for that specific question. After that time switches to the global scores.
 *
 */

const defaultRoundSettings = {
	'cssClass': 'top10',
	'globalScoresScrollDelay': 1,
	'resultsScrollDelay': 5,
	'resultsTime': 10,
	'showPos': false,
	'showTeam': true
};

const sidebarSettings = {
	'lobby': {
		'cssClass': 'top10',
		'globalScoresScrollDelay': 1,
		'resultsScrollDelay': 10,
		'resultsTime': 20,
		'showPos': false,
		'showTeam': true
	},
	'round1': defaultRoundSettings,
	'round2': defaultRoundSettings,
	'round3': defaultRoundSettings,
	'round4': defaultRoundSettings,
	'round5': defaultRoundSettings
};

function GamePage() {
	console.log('Render GamePage')
	
	const {quizId, round} = useParams();
	const [state, setState] = useState({
		quiz: null,
		socket: null,
		message: null
	});
	
	const onCmd = cmd => console.log(cmd);
	
	useEffect(() => {
		if (!['lobby', 'round1', 'round2', 'round3', 'round4', 'round5'].includes(round)) {
			setState({message: 'Invalid Round'});
			return;
		}
		
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/${quizId}/leaderboard`;
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (quiz.error) {
					setState({message: quiz.error});
					return;
				}
				
				const socket = io(`${quiz.serverUrl}/?quizId=${quiz._id}&leaderboard=1`, {transports: ['websocket']});
				socket.on('cmd', onCmd);
				setState({
					message: null,
					quiz,
					socket
				});
				
			} catch (e) {
				console.log(e);
				setState({message: 'Something went wrong'});
			}
		})();
		
		return () => {
			if (state && state.socket) {
				state.socket.disconnect();
			}
		}
	}, [quizId]);
	
	return (
		<div className={`main-page`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <>
				<GameBlock
					quiz={state.quiz}
					socket={state.socket}
					disableScores={round === 'lobby'}
				/>
				<SidebarBlock
					quiz={state.quiz}
					socket={state.socket}
					round={round}
					{...sidebarSettings[round]}
				/>
			</>}
		</div>
	)
}

export default GamePage;
